<template>
  <div class="outlet-item" @click="toDetail">
    <div class="outlet-item__top">
      <van-image class="outlet-item__cover-img" :src="coverImg"/>
      <div class="outlet-item__info">
        <div class="outlet-item__info-row">
          <div class="outlet-item__info-title">
            {{item.storeName}}
          </div>
        </div>
        <div class="outlet-item__info-row">
          <div class="outlet-item__info-tag  outlet-item__info-left">
            <van-tag type="primary" :key="index" v-for="(item, index) in tags">{{item}}</van-tag>
          </div>
        </div>
        <div class="outlet-item__info-row">
          <div class="text-ellipsis outlet-item__info-address  outlet-item__info-left">
            <van-icon name="location" color="#fb6646"/>
            {{item.address}}
          </div>
          <div class="outlet-item__info-distance outlet-item__info-right">
            {{item.distance}}KM
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Image, Icon, Tag} from 'vant';
  import {IMG_DOMAIN} from '../../config'
  import {mapState} from 'vuex';
  export default {
    name: "outletItem",
    props: {item: Object},
    components: {
      vanImage: Image,
      vanIcon: Icon,
      vanTag:Tag
    },
    data() {
      return {}
    },
    computed: {
      ...mapState({
        location :state =>  state.app.location,
      }),
      tags(){
        return this.item.tags ? this.item.tags.split("|") : [];
      },

      coverImg() {
        if(this.item.coverImage){
          return `${IMG_DOMAIN}${this.item.coverImage.url}?imageslim`;
        }
        return '';

      },
    },
    methods:{
      toDetail(){
        this.$router.push(`/store/${this.item.storeId}`);
      }
    }
  }
</script>

<style lang="less" type="text/less" >
  @import "../../assets/less/variable";
  @import "../../assets/less/utils";
  .outlet-item {
    display: flex;
    flex-direction: column;

    padding: 15px;
    box-sizing: border-box;
    position: relative;
    background: @white;

    color: #333;

    &__top {
      display: flex;
    }

    &__cover-img {
      width: 110px;
      height: 90px;
      border-radius: 8px;

      img {
        width: 110px;
        height: 90px;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      flex: 1;

      &-row {
        display: flex;
        height: 30px;
        justify-content: space-between;
      }

      &-title {
        font-size: 13px;
        line-height: 15px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }

      &-left {
        width: 70%;
      }

      &-right {
        width: 30%;
      }

      &-address, &-distance {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        .textOverflow();
      }

      &-address {
        max-width: 150px;
        margin-right: 10px;
      }


    }
  }

  .outlet-item ~ .outlet-item {
    margin-top: 10px;
  }
</style>
