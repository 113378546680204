<template>
  <div class="page">
    <div class="header">
      <van-nav-bar left-text="我的订单" :title="location.city">
        <template slot="right">
          <a href="tel://17768128719">
            <van-icon name="service" color="#FFF" size="20"/>
          </a>
        </template>
      </van-nav-bar>
    </div>
    <div class="main">
      <van-cell-group id="filter-bar">
        <van-row class="filter-bar" type="flex" align="center" justify="space-around">
          <van-col class="filter-bar__condition" span="6" @click="() => {this.areaPopupShow = true}">
            {{this.location.province}}
          </van-col>
          <van-col class="filter-bar__condition" span="6" @click="() => {this.areaPopupShow = true}">{{this.location.city}}
          </van-col>
          <van-col class="filter-bar__condition" span="6">
            <van-dropdown-menu>
              <van-dropdown-item v-model="serviceId" :options="serviceList" />
            </van-dropdown-menu>
          </van-col>
        </van-row>
      </van-cell-group>



        <outlet :key="item.merchantId" :item="item" v-for="item in outletList.data"/>


      <van-popup v-model="areaPopupShow" position="bottom">
        <van-area :area-list="areaList" columns-num="2" @confirm="areaConfirm"
                  @cancel="() => {this.areaPopupShow = false}"/>
      </van-popup>
    </div>
  </div>
</template>

<script>
  import {NavBar, Icon, CellGroup, Row, Col, Popup, Area,DropdownMenu, DropdownItem} from 'vant';
  import areaList from '../config/areaList';
  import {isWeChatBrowser} from '../utils/browserUtil';
  import {listStoreService} from "../service/store";
  import outlet from "../components/Outlet/outletItem";
  import {computeDistance} from '../utils/geoUtil'
  import {mapState} from "vuex";

  export default {
    name: "nearby",
    components: {
      vanNavBar: NavBar,
      vanIcon: Icon,
      vanRow: Row,
      vanCol: Col,
      vanCellGroup: CellGroup,
      vanPopup: Popup,
      vanArea: Area,
      outlet,
      vanDropdownMenu:DropdownMenu,
      vanDropdownItem:DropdownItem

    },
    data() {
      return {
        areaList,
        areaPopupShow: false,
        outletList: {
          loading: false,
          finished: true,
          data: [],
          page: 1,
          size: 99
        },
        serviceId:17,
        serviceList:[
          { text: '网点年检', value: 17 },
        ]
      }
    },
    computed: {
      ...mapState({
        location:state=>state.app.location
      })
    },
    methods: {
      areaConfirm(areaArr) {
        const location = {...this.location};
        location.province = areaArr[0].name;
        location.city = areaArr[1].name;
        location.district = areaArr[0].name;
        this.$store.commit("CURRENT_CITY", {...location})
        this.outletList.page = 0;
        this.outletList.size = 10;
        this.outletList.finished = false;
        this.outletList.data = [];
        this.loadOutletList();
        this.areaPopupShow = false;
      },
      loadOutletList() {
        const _this = this;
        const params = {
          page: this.outletList.page,
          size: this.outletList.size,
          scopeId: this.serviceId,
          province: this.location.province,
          city: this.location.city,
        };
        listStoreService(params).then(resp => {
          if (resp.success) {
            this.outletList.finished = resp.data.content.length < this.outletList.size;
            if (!this.outletList.finished) {
              this.outletList.page++;
            }
            const data = this.outletList.data.concat(resp.data.content);
            const newData =   data.map(item => {
              const distance = computeDistance({
                longitude: _this.location.longitude,
                latitude: _this.location.latitude,
              }, {
                longitude: item.lng,
                latitude: item.lat,
              });
              return {
                ...item,
                distance
              }
            });
            newData.sort((first, second) => {
              return first.distance - second.distance;
            });
            this.outletList.data = newData;
          } else {
            this.outletList.finished = true;
          }
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.outletList.loading = false;
        })
      },
    },
    mounted() {
      this.loadOutletList();
    },
    created() {
      const _this = this;
      if (isWeChatBrowser()) {
        this.$wechat.ready(() => {
          _this.$wechat.getLocation({
            success: function (res) {
              console.log(res);
              _this.amapGeoCode(res.longitude, res.latitude);
            },
            fail: function (error) {
              console.error(error);
            }
          })
        })
      }
    }
  }
</script>

<style scoped type="text/less" lang="less">
  @import "../assets/less/variable";

  .filter-bar {
    height: 40px;

    &__condition {
      text-align: center;
      font-size: @font-size-md;
    }
  }

</style>
